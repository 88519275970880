<template>
  <div class="w-100">
    <b-row class="mt-4">
      <b-col cols="12">
        <div class="w-100 flex justify-content-between align-items-center py-4">
          <h4>Avatar list</h4>
          <!-- add avatar -->
          <b-button @click="handleAddAvatar" class="btn btn-secondary">
            + Add avatar
          </b-button>
        </div>
      </b-col>
      <b-col v-if="avatarList.length === 0 && !loading.getAvatarList" cols="12">
        <div
          class="w-100 flex justify-content-start align-items-center"
          style="color: gray;"
        >
          <b-icon icon="slash-circle" class="mr-2"></b-icon>
          <p>No avatars yet</p>
        </div>
      </b-col>
      <b-col v-if="loading.getAvatarList" cols="12">
        <div class="flex justify-content-start align-items-center">
          <b-spinner label="Loading..." class="ml-4"></b-spinner>
          <span class="ml-4 ">Loading...</span>
        </div>
      </b-col>
      <b-col v-else>
        <b-row>
          <b-col
            v-for="avatar in avatarList"
            :key="avatar.id"
            cols="6"
            md="4"
            lg="3"
            class="mb-4"
          >
            <div class="avatar-card">
              <div class="avatar-card-image">
                <img :src="avatar.url" alt="Avatar" />
              </div>
              <div class="avatar-card-content">
                <h5>{{ avatar.t_nome[view_lang] }}</h5>
                <p>{{ avatar.t_descrizione[view_lang] }}</p>
              </div>
              <div class="avatar-card-action">
                <b-button
                  @click="handleEditAvatar(avatar)"
                  variant="outline-primary"
                >
                  Edit
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal
      id="modal-1"
      title="Avatar Details"
      ok-only
      ok-title="Save"
      size="lg"
      @ok="handleSaveAvatar"
      @hidden="resetModal"
    >
      <div class="w-100 flex justify-content-end align-items-center">
        <LangFlagOptions v-model="view_lang" :options="languages" />
      </div>

      <b-form-group label="Name">
        <b-form-input v-model="tempAvatar.t_nome[view_lang]" />
      </b-form-group>

      <div
        v-if="loading.uploadAvatar"
        class="upload-photo-white-cicrle flex justify-content-center align-items-center"
        style="background-color: white !important; color: lightgray;"
      >
        <b-icon icon="arrow-clockwise" animation="spin" font-scale="2"></b-icon>
      </div>
      <div
        v-else
        class="upload-photo-white-cicrle"
        v-bind:style="{
          backgroundImage: 'url(' + backgroundAvatarImage() + ')',
        }"
      ></div>
      <avatar-cropper
        :trigger="avatarTrigger"
        :labels="{
          submit: 'Save',
          cancel: 'Cancel',
          main: 'Upload new Avatar',
        }"
        :upload-handler="handleAvatarUpload"
        class="mb-4"
      />

      <b-form-group label="Description">
        <b-form-input v-model="tempAvatar.t_descrizione[view_lang]" />
      </b-form-group>

      <b-form-group :label="itemsList.label">
        <b-form-select
          multiple
          v-model="selectedBadges"
          :options="itemsList.items"
          style="height: 130px"
          @change="handleBadgeChange"
        ></b-form-select>
      </b-form-group>

      <div v-if="selectedBadges.length > 0">
        <span class="mb-4">Badge Percentages</span>
        <b-row
          v-for="b in selectedBadges"
          :key="b"
          class=" badge-riga w-100 my-4"
        >
          <b-col cols="12" md="2">
            <img :src="itemByValue(b).url_image" class="w-100 h-auto" />
          </b-col>
          <b-col cols="12" md="8">
            <div
              class="w-100 h-100 flex justify-content-between align-items-center"
            >
              <span class="font-bold" style="font-size: 16px;">{{
                itemByValue(b).title[view_lang]
              }}</span>
              <b-form-group label="Percentage" class="w-25">
                <b-form-input
                  type="number"
                  v-model="avatarBadgeByValue(b).percentuale"
                ></b-form-input>
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="12" md="2">
            <div
              class="w-100 h-100 flex justify-content-end align-items-center"
            >
              <b-button
                @click="handleRemoveBadge(avatarBadgeByValue(b))"
                variant="outline-danger"
                ><b-icon icon="x"></b-icon
              ></b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="loading.addAvatar || loading.editAvatar"
        class="w-100 mt-4 flex justify-content-center align-items-center"
      >
        <b-spinner label="Loading..." class="ml-4"></b-spinner>
        <span class="ml-4 ">Loading...</span>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AxiosService from "@/axiosServices/AxiosService";
import { mapGetters } from "vuex";

import AvatarCropper from "@/components/AvatarCropper.vue";

export default {
  name: "AvatarCrud",

  components: {
    AvatarCropper,
  },

  props: {
    itemsList: {
      type: Object,
    },
  },

  data() {
    return {
      devEnv: false,

      view_lang: null,

      loading: {
        getAvatarList: false,
        saveAvatar: false,
        uploadAvatar: false,

        addAvatar: false,
        editAvatar: false,
        addBadge: false,
      },

      tempAvatar: {
        id: null,
        t_nome: {},
        url: "",
        t_descrizione: {},
        items: [],
      },

      selectedBadges: [],
      originalBadges: [],

      avatarList: [],

      avatarTrigger: null,
    };
  },

  computed: {
    ...mapGetters(["languages"]),
    ...mapGetters(["default_lang"]),
  },

  created() {
    this.view_lang = this.default_lang.value || this.default_lang;

    for (let l of this.languages) {
      this.tempAvatar.t_nome[l.value] = "";
      this.tempAvatar.t_descrizione[l.value] = "";
    }

    this.getAvatarList();
  },

  methods: {
    handleBadgeChange() {
      console.log("badge change", this.selectedBadges, this.tempAvatar.Badges);

      const originalMap = {};
      const nuoviIds = this.selectedBadges;
      const updatedMap = [];

      for (let avatarBadge of this.tempAvatar.Badges) {
        originalMap[avatarBadge.idBadge] = avatarBadge;
      }

      for (let newBadgeId of nuoviIds) {
        if (originalMap[newBadgeId]) {
          updatedMap.push(originalMap[newBadgeId]);
        } else {
          updatedMap.push({
            idBadge: newBadgeId,
            title: {},
            description: {},
            url_image: "",
            ordine: 0,
            percentuale: 0,
          });
        }
      }

      this.tempAvatar.Badges = updatedMap;
    },
    backgroundAvatarImage() {
      return (
        this.tempAvatar.url ||
        "https://placehold.co/400x400?text=Martech\nRevolution"
      );
    },
    async handleAvatarUpload(cropper) {
      const service = new AxiosService("Upload");

      this.loading.uploadAvatar = true;

      cropper
        .getCroppedCanvas({
          aspectRatio: 1,
          autoCropArea: 1,
          viewMode: 1,
          movable: false,
          zoomable: false,
          maxWidth: 1000,
          maxHeight: 1000,
        })
        .toBlob(
          async (blob) => {
            const formData = new FormData();

            formData.append("file", blob, "image.jpg");

            service
              .uploadFile(formData)
              .then((res) => {
                if (res) {
                  this.tempAvatar.url = this.$driveAddress + res.data;
                }
              })
              .catch((err) => {
                console.log("FILE NOT UPLOADED");
                console.log(err);
              })
              .finally(() => {
                this.loading.uploadAvatar = false;
              });
          },
          this.outputMime || this.mimeType,
          this.outputQuality
        );
    },
    itemByValue(value) {
      return this.itemsList.items.find((i) => i.value === value);
    },
    avatarBadgeByValue(value) {
      if (!this.tempAvatar.Badges.find((b) => b.idBadge === value)) {
        return "";
      }

      return this.tempAvatar.Badges.find((b) => b.idBadge === value);
    },
    async getAvatarList() {
      const service = new AxiosService(
        "CampaignManager/GetAvatars/" + this.$route.params.campaignId
      );

      this.avatarList.splice(0);
      this.selectedBadges.splice(0);
      this.originalBadges.splice(0);
      this.loading.getAvatarList = true;

      try {
        this.avatarList = await service.read();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading.getAvatarList = false;
      }
    },

    handleAddAvatar() {
      this.tempAvatar = {
        id: null,
        id_campagna: this.$route.params.campaignId,
        t_nome: {},
        url: "",
        t_descrizione: {},
        Badges: [],
      };
      this.$bvModal.show("modal-1");
    },
    resetModal() {
      this.tempAvatar = {
        id: null,
        id_campagna: this.$route.params.campaignId,
        t_nome: {},
        url: "",
        t_descrizione: {},
        Badges: [],
      };
      this.selectedBadges.splice(0);
      this.originalBadges.splice(0);
      this.getAvatarList();
    },
    handleRemoveBadge(badge) {
      let index = this.tempAvatar.Badges.findIndex(
        (b) => b.idBadge === badge.idBadge
      );
      this.selectedBadges.splice(this.selectedBadges.indexOf(badge.idBadge), 1);
      this.tempAvatar.Badges.splice(index, 1);
      this.deleteBadge(badge);
    },
    handleEditAvatar(avatar) {
      this.tempAvatar = avatar;
      this.selectedBadges.splice(0);
      this.originalBadges.splice(0);
      for (let b of avatar.Badges) {
        this.selectedBadges.push(b.idBadge);
      }

      for (let b of avatar.Badges) {
        this.originalBadges.push(b);
      }
      this.$bvModal.show("modal-1");
    },

    async handleSaveAvatar() {
      console.log("handleSaveAvatar", this.tempAvatar);

      const avatar = { ...this.tempAvatar };

      try {
        if (avatar.id) {
          await this.editAvatar(avatar);
        } else {
          await this.addAvatar(avatar);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.getAvatarList();
        this.$bvModal.hide("modal-1");
      }
    },

    async addAvatar(avatar) {
      const service = new AxiosService(
        "CampaignManager/AddAvatar/" + this.$route.params.campaignId
      );
      this.loading.addAvatar = true;
      try {
        await service.create(avatar);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading.addAvatar = false;
      }
    },

    async editAvatar(avatar) {
      const service = new AxiosService("CampaignManager/EditAvatar");
      this.loading.editAvatar = true;
      try {
        const originalMap = {};

        const nuoviBadges = avatar.Badges;

        for (let originalBadge of this.originalBadges) {
          originalMap[originalBadge.idBadge] = originalBadge;
        }

        for (let nuovoBadge of nuoviBadges) {
          if (!originalMap[nuovoBadge.idBadge]) {
            await this.addBadge(nuovoBadge, avatar);
          }
        }

        await service.update(avatar);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading.editAvatar = false;
      }
    },

    async addBadge(badge, avatar) {
      const service = new AxiosService(
        "CampaignManager/Avatar/AddBadge/" + avatar.id
      );
      this.loading.addBadge = true;
      try {
        await service.create(badge);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading.addBadge = false;
      }
    },

    async deleteBadge(badge) {
      const service = new AxiosService("CampaignManager/Avatar/DeleteBadge");

      try {
        await service.delete(badge.id);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped>
.avatar-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  max-width: 200px;

  margin: 0 auto;
}

.avatar-card-image {
  width: 90%;
  height: 90%;
  padding-top: 15px;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.avatar-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.avatar-card-content {
  padding: 16px;
  text-align: center;
}

.avatar-card-content h5 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.avatar-card-content p {
  margin: 8px 0 0;
  font-size: 0.9rem;
  color: #666;
}

.avatar-card-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 16px 16px;
  width: 100%;
}

.badge-riga {
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }
}
</style>
